import React from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";

export default function ApplicantCard({ titlesList, descList, extraClass }) {
  return (
    <div className={`${styles.card} ${styles[extraClass]}`}>
      <div className={styles.title}>
        {titlesList.map((item, i) => {
          return <h4 key={i}>{item}</h4>;
        })}
      </div>
      <div className={styles.desc}>
        {descList.map((item, i) => {
          return <p key={i}>{item}</p>;
        })}
      </div>
    </div>
  );
}

ApplicantCard.propTypes = {
  titlesList: PropTypes.array,
  descList: PropTypes.array,
  extraClass: PropTypes.string,
};
