import React from "react";

export default function index() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="32px"
      height="25px"
      viewBox="0 0 860.000000 994.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,994.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          fill=" rgb(193, 193, 193)"
          d="M975 9529 c-239 -40 -428 -186 -545 -419 -72 -144 -106 -287 -121
   -515 -7 -108 -9 -1282 -6 -3610 l3 -3450 22 -110 c27 -139 58 -229 118 -347
   39 -75 66 -111 139 -184 73 -74 106 -98 175 -132 129 -64 191 -77 355 -76 158
   0 266 21 411 80 146 58 -57 -56 2429 1369 429 246 943 541 1993 1141 284 163
   796 456 1137 652 341 195 663 383 715 417 413 274 576 630 455 992 -43 128
   -107 224 -230 349 -133 133 -201 179 -615 417 -401 229 -1199 686 -1575 902
   -423 242 -1043 598 -1570 900 -600 344 -1156 663 -1745 1000 -786 451 -831
   477 -910 513 -220 101 -454 142 -635 111z"
        />
      </g>
    </svg>
  );
}
