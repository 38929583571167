import React from "react";

import PropTypes from "prop-types";

import styles from "./style.module.scss";
import {SearchButton} from "../../atoms/SearchButton/index";

function SearchBar({setSearch, extraClass}) {
  return (
    <div className={`${styles.searchContainer} ${styles[extraClass]}`}>
      <input
        placeholder="Search..."
        onChange={e => setSearch(e.target.value)}
      />
      <SearchButton/>
    </div>
  );
}

SearchBar.propTypes = {
  setSearch: PropTypes.func,
  extraClass: PropTypes.string,
};

export default SearchBar;

