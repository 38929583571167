import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";

function inputButton({text}) {

    return (
        <div className={styles.inputButton}>
            <input type="text" placeholder="Enter email addresses, separeted by comma" />
            <div className={styles.buttonSend}><p>{text}</p></div>
        </div>
    );
}

export default inputButton;
inputButton.propTypes = {
    text: PropTypes.string
};
