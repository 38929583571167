
export const objectives = [
    {
        paragraph: "Represent our company, with a comprehensive understanding of our offerings"
    },
    {
        paragraph: "Research consumer needs and identify how our solutions meet them"
    },
    {
        paragraph: "Hire, train, motivate, and advise a team of sales representatives"
    },
    {
        paragraph: "Achieve company objectives through effective planning, setting sales goals, analyzing performance data, and projecting future performance"
    },
    {
        paragraph: "Continuously develop personal leadership, hiring, and training skills while ensuring the team is using  effective sales tactics to meet revenue objectives"
    },
    {
        paragraph: "Generate leads, and build and nurture client relationships"
    }
];


export const responsibilities = [
    {
        paragraph: "Create and execute a strategic sales plan that expands customer base and extends global reach",
    },
    {
        paragraph: "Meet with potential clients and grow long-lasting relationships by understanding their needs",
    },
    {
        paragraph: "Track, analyze, and communicate key quantitative metrics and business trends as they relate to partner relationships",
    },
    {
        paragraph: "Recruit salespeople, set objectives, train and coach, and monitor performance ensuring assigned tasks and responsibilities are fulfilled",
    },
    {
        paragraph: "Identify knowledge gaps within the team and develop a plan to fulfill them"
    },
    {
        paragraph: "Manage month-end and year-end close processes"
    },
];

export const skills = [
    {
        paragraph: "Bachelor’s degree in marketing or business administration"
    },
    {
        paragraph: "7+ years in sales management within a corporate setting"
    },
    {
        paragraph: "Proven track record of success the sales cycle from plan to close"
    },
    {
        paragraph: "Excellent communication, interpersonal, and organizational skills"
    },
    {
        paragraph: "Superb leadership ability"
    },
    {
        paragraph: "Ability to travel at least 20% of the time"
    },
];