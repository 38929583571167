import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";

function TextLink({ title, paragraphs }) {
    return (
        <div className={styles.titleParagraph}>
            <h4>{title}</h4>
            {paragraphs.map(item => {
                return (
                    <p>{item.paragraph}</p>
                );
            })}
        </div>
    );
}
export default TextLink;
TextLink.propTypes = {
    title: PropTypes.string,
    paragraphs: PropTypes.array,
};
