// import styles from "./style.css";
import React from "react";
import styles from "./style.module.scss";
import {
  ComposedChart,
  Line,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Page A",
    uv: 1000,
    // pv: 800,
    amt: 600,
    // cnt: 490
  },
  {
    name: "Page B",
    uv: 1250,
    // pv: 967,
    amt: 1000,
    // cnt: 590
  },
  {
    name: "Page C",
    uv: 800,
    pv: 1098,
    amt: 900,
    cnt: 350,
  },
  {
    name: "Page D",
    uv: 1000,
    pv: 1200,
    amt: 1528,
    cnt: 480,
  },
  {
    name: "Page E",
    uv: 500,
    pv: 1108,
    amt: 600,
    cnt: 460,
  },
  {
    name: "Page F",
    uv: 1200,
    pv: 680,
    amt: 1400,
    cnt: 380,
  },
  {
    name: "Page G",
    uv: 1000,
    pv: 680,
    amt: 1100,
    cnt: 380,
  },
];

export default function DashboardChart() {
  return (
    <div className={styles.chart}>
      <div className={styles.wrapper}>
        <div className={styles.main}>
          <div className={styles.text}>
            <h3>Today's Trends</h3>
            <p>as of 25 March 2022, 09:41 PM</p>
          </div>
          <div className={styles.list}>
            <p>Today</p>
            <p>Week</p>
            <p>Month</p>
            <p>Year</p>
          </div>
        </div>
        <ResponsiveContainer width="95%" height={400}>
          <ComposedChart
            width={1400}
            height={420}
            data={data}
            margin={{
              top: 20,
              right: 80,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" strokeWidth="1px" opacity="0.02" />

            <Tooltip />
            {/* <Legend /> */}
            <Area
              className="area"
              type="monotone"
              dataKey="amt"
              fill="#ec76001e"
              stroke="#EC7600"
            />
            <Line type="monotone" dataKey="uv" stroke="gray" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
