import React from "react";

import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import TableHead from "../../atoms/TableHeadInterview";
import TableRow from "../../atoms/TableRowInterview";

const TableViewInterview = ({ list, selectedOption, handleSort }) => {
    return (
        <table className={styles.tableContainer}>
            <TableHead handleSort={handleSort} selectedOption={selectedOption} />
            {
                list.map((data) => {
                    return <TableRow
                        {...data}
                    />;
                })
            }
        </table>
    );
};

export default TableViewInterview;
TableViewInterview.propTypes = {
    list: PropTypes.array,
    selectedOption: PropTypes.string,
    handleSort: PropTypes.func,
};
