import React from "react";
import styles from "./styles.module.scss";

export default function index({ children, close }) {
  return (
    <div
      onClick={close}
      className={styles.popupRecording}
      onKeyDown={close}
      role="button"
      tabIndex="1000"
    >
      {children}
    </div>
  );
}
