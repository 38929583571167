import React from "react";

export default function index() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6004 9.5001L18.2004 3.0001C18.5004 2.7001 18.6004 2.3001 18.6004 1.9001C18.6004 1.5001 18.4004 1.1001 18.2004 0.800098C17.6004 0.200098 16.6004 0.200098 16.1004 0.800098L9.50039 7.4001L2.90039 0.900098C2.30039 0.300098 1.30039 0.300098 0.800391 0.900098C0.500391 1.2001 0.400391 1.6001 0.400391 2.0001C0.400391 2.4001 0.600391 2.8001 0.800391 3.1001L7.30039 9.6001L0.800391 16.0001C0.500391 16.3001 0.400391 16.7001 0.400391 17.1001C0.400391 17.5001 0.600391 17.9001 0.800391 18.2001C1.40039 18.8001 2.30039 18.8001 2.90039 18.2001L9.50039 11.7001L16.1004 18.2001C16.4004 18.5001 16.8004 18.6001 17.2004 18.6001C17.6004 18.6001 18.0004 18.5001 18.3004 18.2001C18.6004 17.9001 18.7004 17.5001 18.7004 17.1001C18.7004 16.7001 18.5004 16.3001 18.3004 16.0001L11.6004 9.5001Z"
        fill="#C1C1C1"
      />
    </svg>
  );
}
