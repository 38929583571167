import React from "react";

export default function index() {
  return (
    <svg
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="5.13333" height="21.6667" rx="2.56667" fill="#C4C4C4" />
      <rect
        x="10.7334"
        width="5.13333"
        height="21.6667"
        rx="2.56667"
        fill="#C4C4C4"
      />
    </svg>
  );
}
