import React from "react";

export default function index() {
  return (
    <svg
      width="35"
      height="23"
      viewBox="0 0 35 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.4 2.8C34 2.7 33.6 2.7 33.3 2.8L26.8 6.6V3.8C26.8 1.7 25.1 0 23 0H3.8C1.7 0 0 1.7 0 3.8V19C0 21.1 1.7 22.8 3.8 22.8H23C25.1 22.8 26.8 21.1 26.8 19V16.3L33.6 20.1C34 20.3 34.4 20.2 34.6 19.8C34.7 19.7 34.7 19.6 34.7 19.4V3.4C34.8 3.2 34.6 2.9 34.4 2.8Z"
        fill="#C1C1C1"
      />
    </svg>
  );
}
